import { useEffect, useState, useMemo, useCallback } from "react";
import useSWR from "swr";
import dayjs from "dayjs";
import clsx from "clsx";
import { Link } from "@remix-run/react";
import { EventsEmpty } from "../Events/Empty";
import { Card } from "@/components/Card";
import type { EventV2, EventV2ListResponse, MicroCMSAPIContentResponse } from "@/lib/microcms.server";
import { sortEventsBySchedules, sortSchedulesByDate } from "@/lib/microcms";
import { fetcher } from "@/lib/fetcher.client";
import { Calendar } from "@/components/Calendar";
import { Loading } from "@/components/Loader";

export function HomeEvents() {
  const [day, setDay] = useState<dayjs.Dayjs>(dayjs());

  const getLastMonthEnd = useCallback(
    (day: dayjs.Dayjs) =>
      day
        .clone()
        .set("year", day.get("year"))
        .set("month", day.get("month") - 1)
        .endOf("month")
        .format("YYYY-MM-DD"),
    []
  );

  const getNextMonthStart = useCallback(
    (day: dayjs.Dayjs) =>
      day
        .clone()
        .set("year", day.get("year"))
        .set("month", day.get("month") + 1)
        .startOf("month")
        .format("YYYY-MM-DD"),
    []
  );

  const buildEventQuery = useCallback(
    (start: string, end: string, limit = 1000) =>
      `?filters=date_to_search[greater_than]${start}[and]date_to_search[less_than]${end}&limit=${limit}`,
    []
  );

  const onCurrentMonthChange = useCallback(
    (day: dayjs.Dayjs) => {
      setFetchQuery(buildEventQuery(getLastMonthEnd(day), getNextMonthStart(day)));
    },
    [buildEventQuery, getLastMonthEnd, getNextMonthStart]
  );

  const [fetchQuery, setFetchQuery] = useState<string | null>(null);

  const { data: v2events, isValidating: v2Validating } = useSWR<EventV2ListResponse | null>(
    fetchQuery ? `/api/v2/events${fetchQuery}` : null,
    fetcher,
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    setFetchQuery(buildEventQuery(getLastMonthEnd(day), getNextMonthStart(day)));
  }, []);

  const sortedEvents = useMemo(() => {
    if (!v2events) return null;

    return sortEventsBySchedules(v2events.contents) as MicroCMSAPIContentResponse<EventV2>[];
  }, [v2events]);

  return (
    <Card className={clsx("lg:min-h-[700px]", "lg:max-h-[700px]")}>
      <div className={clsx("flex", "flex-col", "flex-grow")}>
        <div className={clsx("min-w-0", "min-h-[36px]")}>
          <Calendar day={day} onDayChange={setDay} onCurrentMonthChange={onCurrentMonthChange}>
            <Calendar.Control>
              <div className={clsx("flex", "justify-between", "items-center")}>
                <Calendar.Prev extend>
                  <button
                    className={clsx(
                      "flex",
                      "items-center",
                      "justify-center",
                      "p-2",
                      "rounded",
                      "text-blue-600",
                      "hover:bg-interaction",
                      "focus:bg-interaction"
                    )}
                  >
                    <svg className="fill-current" viewBox="0 0 18 18" width="20" height="20">
                      <path
                        d="m13.7 16.29a1 1 0 1 1 -1.42 1.41l-8-8a1 1 0 0 1 0-1.41l8-8a1 1 0 1 1 1.42 1.41l-7.29 7.29z"
                        fillRule="evenodd"
                      />
                    </svg>
                  </button>
                </Calendar.Prev>

                <div className={clsx("min-w-0", "max-w-full", "flex-grow")}>
                  <div className={clsx("block", "text-center", "min-w-0")}>
                    <Calendar.Reset>
                      <Calendar.Label>
                        {({ day }) => {
                          return <span className={clsx("text-lg", "font-bold")}>{day.format("YYYY年M月")}</span>;
                        }}
                      </Calendar.Label>
                    </Calendar.Reset>
                  </div>
                </div>

                <Calendar.Next extend>
                  <button
                    className={clsx(
                      "flex",
                      "items-center",
                      "justify-center",
                      "p-2",
                      "rounded",
                      "text-blue-600",
                      "hover:bg-interaction",
                      "focus:bg-interaction"
                    )}
                  >
                    <svg className="fill-current rotate-180" viewBox="0 0 18 18" width="20" height="20">
                      <path
                        d="m13.7 16.29a1 1 0 1 1 -1.42 1.41l-8-8a1 1 0 0 1 0-1.41l8-8a1 1 0 1 1 1.42 1.41l-7.29 7.29z"
                        fillRule="evenodd"
                      />
                    </svg>
                  </button>
                </Calendar.Next>
              </div>
            </Calendar.Control>
          </Calendar>
        </div>

        <div
          className={clsx("min-w-0", "flex-grow", "lg:max-h-[calc(700px-72px-theme(padding.6)*2)]", "overflow-auto")}
        >
          <div className={clsx("relative", "overflow-auto")}>
            <ul className={clsx("py-8", "px-2")}>
              {sortedEvents?.map((event, i) => {
                const displayDate = (dates: typeof event["schedules"]) => {
                  if (dates.length === 1) {
                    return dayjs(dates[0].date).format("YYYY/M/D (dd)");
                  }
                  const sorted = sortSchedulesByDate(dates);
                  return `${dayjs(sorted[0].date).format("YYYY/M/D (dd)")} ~ ${dayjs(
                    sorted[sorted.length - 1].date
                  ).format("YYYY/M/D (dd)")}`;
                };
                return (
                  <li
                    key={event.id}
                    className={clsx("block", "mb-4", i !== 0 && ["pt-4", "border-t", "border-t-gray-200"])}
                  >
                    <Link
                      to={`/events/article/${event.id}`}
                      className={clsx("block", "hover:underline", "focus:underline")}
                    >
                      <div className="space-y-2">
                        <span className={clsx("block", "text-sm", "font-bold", "text-brand-royalBlue")}>
                          {displayDate(event.schedules)}
                        </span>
                        <h3 className={clsx("font-black", "text-gray-900")}>{event.title}</h3>
                      </div>
                    </Link>
                  </li>
                );
              })}
              {sortedEvents && sortedEvents.length === 0 && !v2Validating && <EventsEmpty />}
              {(!sortedEvents || sortedEvents.length === 0) && v2Validating && <span className="spacer mt-12 ml-12" />}
            </ul>
            {v2Validating && <Loading.Absolute />}
          </div>
        </div>

        <div className="min-w-0 min-h-[36px] flex">
          <div className={clsx("flex", "justify-end", "items-end", "w-full")}>
            <p>
              <Link to="/vacancies" className="button button--plain">
                会場の予約状況を確認する
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
}
