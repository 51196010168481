import type { HeadersFunction, MetaFunction } from "@remix-run/node";
import { json } from "@remix-run/node";
import { Fragment } from "react";
import clsx from "clsx";
import { useLoaderData } from "@remix-run/react";
import { HomeHero } from "../components/Home/Hero";
import { createOGP } from "../utilities/ogp";
import { fetchSupporterList, fetchTopicsList } from "@/lib/microcms.server";
import { PageContainer } from "@/components/PageContainer";
import { HomeSection } from "@/components/Home/Section";
import { HomeTopics } from "@/components/Home/Topics";
import { HomeTwitter } from "@/components/Home/Twitter";
import { HomeEvents } from "@/components/Home/Events";
import { HomeMessage } from "@/components/Home/Message";
import { HomeSupporters } from "@/components/Home/Supporters";
import { HomeAccess } from "@/components/Home/Access";
import { CacheRevalidateMinutes } from "@/lib/responses.server";

export const handle = {
  home: true,
};

export const meta: MetaFunction = () => {
  return createOGP();
};

export const headers: HeadersFunction = () => {
  return {
    "Cache-Control": CacheRevalidateMinutes(),
  };
};

export async function loader() {
  const topics = await fetchTopicsList(0, 6).catch(() => null);
  const supporters = await fetchSupporterList(0, 3).catch(() => null);
  const mediaUrl = `${process.env.WORKER_DOMAIN}/niigata-ookama.mp4`;

  return json({ topics, supporters, mediaUrl });
}

export default function Home() {
  const { topics, supporters, mediaUrl } = useLoaderData<typeof loader>();

  return (
    <Fragment>
      <HomeHero mediaUrl={mediaUrl} />
      <span className="spacer mt-20" />
      <PageContainer hideBorder>
        <div className={clsx("grid", "grid-cols-1", "gap-12", "lg:grid-cols-4", "lg:gap-16")}>
          <div className={clsx("flex", "flex-col", "col-span-1")}>
            <div className={clsx("flex", "flex-col", "flex-grow")}>
              <HomeSection title="トピックス">
                <HomeTopics topics={topics?.contents || []} />
              </HomeSection>
            </div>
          </div>

          <div className={clsx("flex", "flex-col", "col-span-1", "lg:col-span-2")}>
            <div className={clsx("flex", "flex-col", "flex-grow")}>
              <HomeSection title="イベント">
                <HomeEvents />
              </HomeSection>
            </div>
          </div>

          <div className={clsx("flex", "flex-col", "col-span-1")}>
            <div className={clsx("flex", "flex-col", "flex-grow")}>
              <HomeSection title="X（エックス）">
                <HomeTwitter />
              </HomeSection>
            </div>
          </div>
        </div>
      </PageContainer>

      <span className="spacer mt-20" />

      <PageContainer hideBorder>
        <div className={clsx("grid", "grid-cols-1", "gap-12", "lg:grid-cols-3", "lg:gap-16")}>
          <div className={clsx("flex", "flex-col", "col-span-1")}>
            <div className={clsx("flex", "flex-col", "flex-grow")}>
              <HomeSection title="メッセージ">
                <HomeMessage />
              </HomeSection>
            </div>
          </div>

          <div className={clsx("flex", "flex-col", "col-span-1", "lg:col-span-2")}>
            <div className={clsx("flex", "flex-col", "flex-grow")}>
              <HomeSection title="おおかまサポーター">
                <HomeSupporters supporters={supporters?.contents || []} />
              </HomeSection>
            </div>
          </div>
        </div>
      </PageContainer>

      <span className="spacer mt-20" />

      <PageContainer hideBorder>
        <HomeSection title="アクセス">
          <HomeAccess />
        </HomeSection>
      </PageContainer>

      <span className="spacer mt-20" />
    </Fragment>
  );
}
