import clsx from "clsx";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import { Link } from "@remix-run/react";
import { SupporterCard } from "../SupporterCard";
import type { MicroCMSAPIContentResponse, Supporter } from "@/lib/microcms.server";

interface HomeSupportersProps {
  supporters: MicroCMSAPIContentResponse<Supporter>[];
}

export function HomeSupporters({ supporters }: HomeSupportersProps) {
  return (
    <div
      className={clsx(
        "grid",
        "gap-4",
        "scroll-px-4",
        "overflow-x-auto",
        "overflow-y-hidden",
        "-mx-4",
        "px-4",
        "pb-6",
        "grid-flow-col",
        "sm:grid-cols-3",
        "sm:scroll-px-0",
        "overflow-visible",
        "mx-0",
        "p-0",
        "sm:grid-flow-row-dense"
      )}
      style={{ scrollSnapType: "x mandatory" }}
    >
      {supporters.map((v) => (
        <SupporterCard key={v.id} supporter={v} fullHeight={false} />
      ))}
      <div className={clsx("flex", "items-center", "sm:col-span-3", "sm:justify-end", "sm:mt-8")}>
        <Link
          to="/supporters"
          className={clsx(
            "w-16",
            "h-16",
            "bg-white",
            "rounded",
            "flex",
            "items-center",
            "justify-center",
            "text-blue-600",
            "sm:w-auto",
            "sm:h-auto",
            "py-3",
            "px-5"
          )}
        >
          <span className={clsx("hidden", "sm:block")}>もっと見る</span>
          <span className={clsx("block", "ml-2", "sm:hidden")}>ALL</span>
          <ChevronRightIcon width={20} height={20} />
        </Link>
      </div>
      <div className={clsx("w-4", "h-4", "-ml-4", "sm:hidden")} />
    </div>
  );
}
