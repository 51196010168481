import clsx from "clsx";
import common from "../../data/common.json";
import home from "../../data/home.json";

export function HomeAccess() {
  return (
    <div>
      <div className="mb-4">
        <h3 className={clsx("font-sans", "font-bold", "text-base", "text-gray-900", "mb-2")}>万代島多目的広場</h3>
        <p className={clsx("text-sm", "text-gray-900")}>{`〒${common.website.info.manager.postal_code}`}</p>
        <p className={clsx("text-sm", "text-gray-700")}>{common.website.info.manager.address}</p>
      </div>
      <div className={clsx("grid", "grid-cols-1", "gap-8", "lg:grid-cols-2")}>
        <div className={clsx("flex", "flex-col", "order-1", "col-span-1", "lg:order-2")}>
          <div>
            <img
              src="/images/home/view_b_eye.jpg"
              width={480}
              height={360}
              alt="航空写真。万代島多目的広場と朱鷺メッセが見えます。"
              className={clsx("rounded-xl", "object-cover", "w-full")}
            />
          </div>
        </div>
        <div className={clsx("flex", "flex-col", "order-2", "col-span-1", "lg:order-1")}>
          <iframe
            title={"Google Maps"}
            src={home.google_map_embed_source}
            height={"100%"}
            frameBorder={"0"}
            className={clsx("border-0", "rounded-xl")}
            allowFullScreen={false}
            aria-hidden={false}
          />
        </div>
      </div>
    </div>
  );
}
