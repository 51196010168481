import clsx from "clsx";
import { Card } from "../Card";
import common from "../../data/common.json";

export function HomeMessage() {
  return (
    <Card>
      <div className="space-y-4">
        <h3 className={clsx("font-black", "text-xl", "text-center", "text-blue-600")}>{common.meta.catchphrase}</h3>

        <p className={clsx("text-gray-700", "whitespace-pre-wrap", "text-center")}>
          新潟市民の皆様がいつでも
          <br />
          気軽に足を運んでいただけるような
          <br />
          楽しいにぎわいをご用意して
          <br />
          お待ちしております。
          <br />
          <br />
          どの年代の方でも楽しめるような
          <br />
          様々なジャンルのフードイベント、
          <br />
          四季を感じながら楽しめるイベント、
          <br />
          大型イベントのタイアップなどを
          <br />
          行っていきます。
          <br />
          また市民団体の発表の場としても
          <br />
          利用できるように運営をしていきます。
          <br />
          <br />
          あなたの心を打つ…
          <br />
          新潟のオアシスになるように…
        </p>

        <div className="text-right">
          <span className={clsx("text-gray-600", "block", "text-xs")}>Produced by</span>
          <span className={clsx("text-gray-700", "block", "text-sm")}>{common.website.info.manager.name}</span>
        </div>
      </div>
    </Card>
  );
}
