import clsx from "clsx";
import { memo } from "react";
import common from "../../data/common.json";

interface HomeHeroProps {
  mediaUrl: string;
}

export const HomeHero = memo(function HomeHero({ mediaUrl: _mediaUrl }: HomeHeroProps) {
  return (
    <div className={clsx("relative", "h-[540px]", "sm:h-[636px]", "md:h-[860px]")}>
      <div
        className={clsx(
          "absolute",
          "inset-0",
          "overflow-hidden",
          "w-full",
          "h-full",
          "after:absolute",
          "after:inset-0",
          "after:bg-[url(/images/dot.png)]"
        )}
      >
        <video
          autoPlay
          muted
          loop
          playsInline
          className={clsx(
            "w-auto",
            "h-auto",
            "min-w-full",
            "min-h-full",
            "object-cover",
            "-translate-x-1/2",
            "-translate-y-1/2",
            "bg-cover",
            "absolute",
            "top-1/2",
            "left-1/2"
          )}
        >
          <source key="hero[.0]" src="/videos/niigata-ookama.mp4" type="video/mp4" />
          {/* <source key="hero[.0]" src={mediaUrl} type="video/mp4" /> */}
        </video>
      </div>

      <div className={clsx("absolute", "inset-0", "flex", "items-center")}>
        <h1
          className={clsx(
            "px-8",
            "mx-auto",
            "max-w-[56rem]",
            "font-black",
            "text-white",
            "text-center",
            "text-[4rem]",
            "md:text-8xl"
          )}
        >
          {common.meta.catchphrase}
        </h1>
      </div>
    </div>
  );
});
