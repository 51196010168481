import clsx from "clsx";
import type { ReactNode } from "react";

interface HomeSectionProps {
  title: string;
  children: ReactNode;
}

export function HomeSection({ title, children }: HomeSectionProps) {
  return (
    <section className={clsx("flex-grow", "max-h-full")}>
      <div className={clsx("h-full", "max-h-full", "space-y-8", "flex", "flex-col")}>
        <div className={clsx("min-w-0")}>
          <div className={clsx("border-b", "border-b-blue-200")}>
            <h2 className={clsx("font-bold", "text-gray-900", "text-2xl", "pb-2")}>{title}</h2>
          </div>
        </div>

        <div className={clsx("min-w-0", "flex-grow")}>{children}</div>
      </div>
    </section>
  );
}
