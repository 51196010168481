import { Link } from "@remix-run/react";
import clsx from "clsx";
import dayjs from "dayjs";
import type { MicroCMSAPIContentResponse, Topics } from "@/lib/microcms.server";

export interface HomeTopicsProps {
  topics: MicroCMSAPIContentResponse<Topics>[];
}

export function HomeTopics({ topics }: HomeTopicsProps) {
  return (
    <ul className={clsx("lg:min-h-[700px]", "lg:max-h-[700px]", "overflow-y-auto")}>
      {topics.map((item) => (
        <li key={item.id}>
          <Link
            to={`/topics/article/${item.id}`}
            className={clsx("block", "hover:underline", "focus:underline")}
            aria-label={item.title}
          >
            <div className={clsx("py-4", "relative", "border-b", "border-b-gray-300")}>
              <div className={clsx("flex", "items-center", "font-bold", "text-sm", "mb-2")}>
                <span className={clsx("block", "text-gray-700")}>
                  {dayjs(item.publishedAt).format("YYYY/M/D (dd)")}
                </span>
                <span className={clsx("block", "text-gray-700", "mx-2")}>・</span>
                <span
                  className={clsx(
                    "block",
                    item.category[0] === "お知らせ" && "text-brand-green",
                    item.category[0] === "イベントレポート" && "text-brand-red"
                  )}
                >
                  {item.category[0]}
                </span>
              </div>
              <span className={clsx("block", "font-black", "text-base", "text-gray-900")}>{item.title}</span>
            </div>
          </Link>
        </li>
      ))}
    </ul>
  );
}
