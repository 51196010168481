import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { Card } from "../Card";
import { Loading } from "../Loader";
import common from "../../data/common.json";

export function HomeTwitter() {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [iframeHeight, setIframeHeight] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const timelineRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    const t = window.setTimeout(() => {
      if (containerRef.current && timelineRef.current) {
        setIframeHeight(containerRef.current.clientHeight);
        const script = document.getElementById("twitter-widgets");
        if (script) {
          script.remove();
        }
        const s = document.createElement("script");
        s.async = true;
        s.id = "twitter-widgets";
        s.src = "https://platform.twitter.com/widgets.js";
        s.onload = () => {
          const timer = window.setTimeout(() => {
            setIsActive(true);
            window.clearTimeout(timer);
          }, 1000);
        };
        document.getElementsByTagName("body")[0].appendChild(s);
        window.clearTimeout(t);
      }
    }, 100);
    return () => {
      window.clearTimeout(t);
    };
  }, []);

  return (
    <Card className={clsx("min-h-[400px]", "lg:min-h-[700px]")}>
      <div className={clsx("h-full", "relative", "overflow-hidden", "flex-grow")} ref={containerRef}>
        <a
          ref={timelineRef}
          className={"twitter-timeline"}
          aria-label={"twiiter"}
          data-element={"twitter"}
          data-height={iframeHeight}
          data-theme={"light"}
          data-chrome={"noheader nofooter noscrollbar transparent noborders"}
          href={`https://twitter.com/${common.meta.twitter_name}?ref_src=twsrc%5Etfw`}
        >
          {common.meta.twitter_name}
        </a>
        {!isActive && (
          <div className={clsx("absolute", "inset-0", "bg-white", "flex", "items-center")}>
            <Loading.Base />
          </div>
        )}
      </div>
    </Card>
  );
}
